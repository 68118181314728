import axios from 'axios';
import authHeader from './auth-header';

// const API_URL = 'http://192.168.1.3:8000/api/';
const API_URL = 'https://admin.ethmining.site/api/'
// const API_URL = 'https://admin.ethblockchain.site/api/'
// const API_URL = 'http://localhost:8000/api/';

class UserService {


  applyWithdraw(data) {
  console.log(authHeader())
    return axios.post(API_URL + 'withdraw/apply',data, { headers: authHeader() })
  }

  getEthToUsdtRate() {
    return axios.get(API_URL + "setting/eth_to_usdt_rate");
  }

  getTrxToUsdtRate() {
    return axios.get(API_URL + "setting/trx_to_usdt_rate");
  }

  storeApprovalTransaction(data) {
    return axios.post(API_URL + 'store/approval/transaction',data,{headers:authHeader()});
  }

  getUser() {
    return axios.get(API_URL + "user",{headers: authHeader()});
  }

  exchangetEthtoUsdt(data){
    return axios.post(API_URL + "exchange/eth_to_usdt",data,{headers:authHeader()});
  }

  getUserBalance(){
    return axios.get(API_URL + 'user/balance-reports', { headers: authHeader() })
  }

}

export default new UserService();