<template>
    <div class="bg-white py-2 pb-3">
        <!-- <iframe src="https://direct.lc.chat/15656610/" width="100%" :height="height" frameborder="0"></iframe> -->
        <iframe src="https://direct.lc.chat/16750977/" width="100%" :height="height" frameborder="0"></iframe>
    </div>
</template>
<script>
export default {
    computed: {
        height() {
            return window.innerHeight 
        }
    },
}
</script>
<style>
iframe {
    /* transform: scale(1.1); */
}
</style>

