<template>
    <BannerComponent></BannerComponent>

    <div class="p-3">
        <div class="bg-pri rounded rounded-3 mt-2 p-3">
            <h6>{{ $t('my_level') }}</h6>
            <div class="d-flex align-items-center">
                <img src="@/assets/vip.png" style="width: 20px;" alt="">
                <!-- <font-awesome-icon size="lg" style="color:#84319B" :icon="['fas', 'gem']" /> -->
                <!-- <div class="fw-bold ms-2">
                    <h5 class="mb-0">{{ $t('level_1') }}</h5>
                </div> -->
            </div>
        </div>

        <div class="dotted-line my-3"></div>

        <div>
            <div class="d-flex px-1 mb-1">
                <div class="col-4 px-1" :key="index" v-for="(nav, index) in navs">
                    <div style="font-size: .8rem;" :class="{ 'active': selectedNav == index }" @click="chooseNav(index)"
                        class="type-btn px-1 d-flex align-items-center justify-content-center py-2">
                        <b>{{ $t(nav.name) }}</b>
                    </div>
                </div>
            </div>
            <div v-if="selectedNav == 0" class="mt-2">
                <div class="row mt-2">
                    <div class="col-6 mb-2 pe-1">
                        <div style="background-color: #25345f;" class="text-white rounded d-flex align-items-center p-3">
                            <div class="bg-dark">
                                <img src="@/assets/surbodinate.png" style="width: 40px;" alt="">
                            </div>
                            <div class="ms-2">
                                <p class="mb-0">{{ $t('team_scale') }}</p>
                                <b>0</b>
                            </div>
                        </div>
                    </div>

                    <div class="col-6 mb-3 ps-1">
                        <div style="background-color: #25345f;" class="text-white rounded d-flex align-items-center p-3">
                            <div class="bg-dark">
                                <img src="@/assets/team.png" style="width: 40px;" alt="">
                            </div>
                            <div class="ms-2">
                                <p class="mb-0">{{ $t('surbodinate') }}</p>
                                <b>0</b>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <TodayIncomeComponent class="mt-2"></TodayIncomeComponent> -->

            </div>
            <div v-else-if="selectedNav == 1" class="mt-2">
                <!-- <div class="row mt-2">
                    <h5 class="">My Share Link</h5>
                </div> -->


                <div style="background-color: #25345f;" class=" rounded p-3">
                    <h5 class="text-center fw-bold my-share-link" style="font-size:24px">{{ $t('my_share_link') }}</h5>
                    <p class="text-pri">
                        {{ $t('share_note') }}
                    </p>
                    <!-- <div class="text-center">
                        <span class="me-2">{{ $t('copy_link') }}</span> <font-awesome-icon :icon="['far', 'copy']" />
                    </div> -->
                    <div class="d-flex rounded justify-content-between p-2 px-3"
                        style="height: 50px;background-color: #19196f!important;">
                        <div class="text-start mb-0 text-white-50">{{ this.site_url }}</div>
                        <img src="@/assets/fuzhi.png" style="width: 30px;" alt="">
                    </div>
                </div>

                <div class="d-flex align-items-center text-center mt-3">
                    <div class="col-4 text-pri">
                        1.Extract link
                    </div>
                    <div class="col-4 text-pri">
                        2.Invite a friend
                    </div>
                    <div class="col-4 text-pri">
                        3.Earn crypto currency
                    </div>
                </div>
                <div class="row mt-1">
                    <img src="@/assets/share.png" alt="">
                </div>

            </div>

            <div v-else-if="selectedNav == 2" class="mt-2">
                <!-- <div class="row mt-2">
                    <h5 class="">My Share Link</h5>
                </div> -->


                <div class="bg-pri p-3">
                    <div class="d-flex ">
                        <div @click="selectedLevel = index" class="level me-2" v-for="(level, index) in levels"
                            :key="index">
                            <div class="h5">{{ $t(level) }}</div>
                            <div v-if="selectedLevel == index" class="line rounded" style="height: 6px;"></div>
                        </div>
                    </div>
                    <div class="line2 my-2"></div>
                    <table class="table text-white">
                        <tr>
                            <th class="text-pri">{{ $t('level') }}</th>
                            <th class="text-pri">{{ $t('username') }}</th>
                            <th class="text-pri">TRX</th>
                            <th class="text-pri">ETH</th>
                        </tr>
                    </table>
                </div>

            </div>

        </div>
    </div>
</template>
<script>

import BannerComponent from '@/components/share/BannerComponent.vue';
// import TodayIncomeComponent from '@/components/share/TodayIncomeComponent.vue';
export default {
    components: {
        BannerComponent
    },
    data() {
        return {
            selectedNav: 0,
            selectedLevel: 0,
            levels: ['all', 'level_1', 'level_2', 'level_3'],
            navs: [
                {
                    name: 'team_scale',
                },
                {
                    name: 'share',
                },
                {
                    name: 'log',
                }
            ],
            site_url: window.location.origin
        }
    },
    methods: {
        chooseNav(index) {
            this.selectedNav = index
        }
    }

}
</script>
<style scoped>
.level.active {
    border-bottom: 3px solid #84319B;
}


.type-btn {
    background: #25345f;
    color: white;
    border-radius: 8px;
    position: relative;

}

.type-btn.active {
    background: #1a253a;
}

.type-btn.active::after {
    position: absolute;
    top: -2.5px;
    bottom: -2.5px;
    left: -2.5px;
    right: -2.5px;
    background: linear-gradient(90deg, #8F60FF, #FF98D7) !important;
    content: '';
    z-index: -1;
    border-radius: 8px;
}

.my-share-link {
    background-image: linear-gradient(90deg, #4BB8FF, #8048FE) !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>