<template>
  <div v-if="currentRouteName == 'support'">
    <div>
                <nav style="height: 50px;"
                  class="position-relative nav px-4 d-flex align-items-center bg-nav justify-content-center">
                  <img style="width: 40px;" src="@/assets/logo.png" alt="">
                  <span style="font-size:1.2rem;font-weight:bold;background-image: linear-gradient(90deg,#4BB8FF,#8048FE)!important;-webkit-background-clip: text;
            -webkit-text-fill-color: transparent;">ECOS Ether Cloud</span>

                  <div class="position-absolute justify-content-end text-white d-flex align-items-center"
                    style="top: 0;left: 0;width: 100vw;height: 50px;">
                    <small>{{ displayAddress }}</small>
                  </div>
                </nav>
              </div>
        <div class="position-fixed" style="top: 0;right: 0;width: 100vw;height: 100vh;">
          <router-view></router-view>
        </div>

      </div>
      <div v-else>

          <div >
            <router-link :to="{ name: 'support' }" class="position-fixed" style="z-index: 999;right:15px;bottom:8%">
            <img width="50" src="@/assets/cs.png" alt="">
          </router-link>
          <nav style="height: 50px;"
                  class="position-relative nav px-4 d-flex align-items-center bg-nav justify-content-center">
                  <img style="width: 40px;" src="@/assets/logo.png" alt="">
                  <span style="font-size:1.2rem;font-weight:bold;background-image: linear-gradient(90deg,#4BB8FF,#8048FE)!important;-webkit-background-clip: text;
            -webkit-text-fill-color: transparent;">ECOS Ether Cloud</span>

                  <div class="position-absolute justify-content-end text-white d-flex align-items-center"
                    style="top: 0;left: 0;width: 100vw;height: 50px;">
                    <small>{{ displayAddress }}</small>
                  </div>
                </nav>
        </div>
        <div class="mb-5">
            <router-view></router-view>
          </div>
        <footer-nav class="position-fixed vw-100" style="bottom: 0;"></footer-nav>

      </div>
            <!-- <div>
              <div>
                <nav style="height: 50px;"
                  class="position-relative nav px-4 d-flex align-items-center bg-nav justify-content-center">
                  <img style="width: 40px;" src="@/assets/logo.png" alt="">
                  <span style="font-size:1.2rem;font-weight:bold;background-image: linear-gradient(90deg,#4BB8FF,#8048FE)!important;-webkit-background-clip: text;
            -webkit-text-fill-color: transparent;">ECOS Ether Cloud</span>

                  <div class="position-absolute justify-content-end text-white d-flex align-items-center"
                    style="top: 0;left: 0;width: 100vw;height: 50px;">
                    <small>{{ displayAddress }}</small>
                  </div>
                </nav>
              </div>
              <div class="mb-5">
                <router-view></router-view>
              </div>
              <footer-nav class="position-fixed vw-100" style="bottom: 0;"></footer-nav>
            </div> -->
</template>

<script>
import FooterNav from './components/FooterNav.vue';
import { useHead } from '@vueuse/head'
// import WalletConnect from "@walletconnect/client";
// import QRCodeModal from "@walletconnect/qrcode-modal";
// import { Buffer } from 'buffer';
import Web3 from 'web3';

export default {
  name: 'App',
  setup() {
    useHead({
      // Can be static or computed
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
        },
      ],

    })
  },
  data() {
    return {
      tokenAddress: this.$store.state.tokenAddress
    }
  },
  mounted() {
    this.initializeWeb3()
  },
  computed: {
    displayAddress() {
      if (this.user) {
        let address = this.user.wallet_address
        let length = address.length
        return address[0] + address[1] + address[2] + address[3] + '....' + address[length - 4] + address[length - 3] + address[length - 2] + address[length - 1]
      }
      return null;
      // return 'hello user address';
    },

    user() {
      return this.$store.state.auth.user
    },
    currentRouteName() {
      return this.$router.currentRoute.value.name
    }
  },
  methods: {
    changeLanguage(code) {
      this.$i18n.locale = code
    },
    async initializeWeb3() {
      if (typeof window.ethereum !== "undefined") {
        await window.ethereum.enable();
        const web3 = new Web3(window.ethereum);
        if (!this.$store.state.auth.status.loggedIn) {
          const accounts = await web3.eth.getAccounts();
          const userAddress = accounts[0]; // Assuming the user has connected their wallet and has at least one account
          this.$store.dispatch('auth/register', {
            address: userAddress
          }).then(() => {
            alert('Wallet connected!');
          }).catch((response) => {
            console.log(response)
            alert('Can not connect wallet!')
          })
        }
      }
    },
  },
  components: {
    FooterNav
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}


.bg-pri {
  background-color: #19196f !important;
  color: white !important;
}

.bg-nav {
  background-color: #19196f !important;
}

.bg-sec {
  background-color: #2b3139 !important;
}

.text-sec {
  color: #dbe9f7 !important;
}

.bg-card {
  background-color: #1e2328 !important;
  color: white;
}

body {
  min-height: 100vh;
  background-color: #fff !important;
}

.text-pri {
  color: #7a90d3 !important;
}


.line {
  height: 3px;
  background-image: linear-gradient(90deg, #FF98D7, #8F60FF) !important;
}

.line2 {
  height: 3px !important;
  background: #5e72a6 !important;
  /* background-image: linear-gradient(90deg,#FF98D7,#8F60FF)!important; */
}

.dotted-line {
  background-image: linear-gradient(to right, rgb(94, 115, 166) 0%, rgb(94, 115, 166) 50%, transparent 50%);
  background-size: 20px 3px;
  background-repeat: repeat-x;
  height: 2px;
}

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", 'Inter', "Helvetica Neue", sans-serif;
  font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", 'Inter', "Helvetica Neue", sans-serif;
}
</style>

