<template>
    <div>
        <div class="d-flex align-items-center mb-2">
            <h5 class="text-dark fw-bold mb-0">{{ $t("mining") }}</h5>

            <small class="text-dark  ms-2">{{ $t('Liquitidy') }}</small>
        </div>
        <div class="bg-pri p-3 rounded">
            <h5 class="text-center title" style="color: #84319B!important;"><b>{{ $t("miner_income") }}</b></h5>
            <div class="line2 my-2"></div>
            <div class="row text-sec mb-2">
                <div class="col-7">
                    <b>{{ $t('block_address') }}</b>
                </div>
                <div class="col-5 text-end">
                    <b>{{ $t("quantity") }}</b>
                </div>
            </div>
            <marquee behavior="scroll" infinite direction="up" scrolldelay="800" height="50px">
                <div style="position: relative;bottom:100px">
                    <div class="row" v-for="(rand, index) in rands" :key="index">
                        <span style="color: #759ce6!important;" class="col-7">
                            {{ rand.address }}
                        </span>
                        <span style="color:#ff1c99!important" class="col-5 text-end">
                            {{ rand.quantity }} <small>{{ rand.unit }}</small>
                        </span>
                    </div>
                </div>
            </marquee>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            rands: []
        }
    },
    mounted() {
        for (let i = 0; i < 99; i++) {
            let int = (Math.floor(Math.random() * 100) + 1) % 2;
            if (int == 0) {
                this.rands.push({
                    address: 'T' + this.randomString(),
                    quantity: this.randomNumber(),
                    unit: 'TRX'
                })
            } else {
                this.rands.push({
                    address: 'Ox' + this.randomString(),
                    quantity: this.randomNumber(),
                    unit: 'ETH'
                })
            }
        }
    },
    methods: {
        randomString() {
            // declare all characters
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '', length = 20;
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                if (i < 8 || i > 14) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                } else {
                    result += '*';
                }
            }

            return result;
        },

        randomNumber() {
            let maximum = 1000000, minimum = 1;
            let decimal = Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;

            maximum = 10;
            minimum = 1;
            let number = Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;

            return number + '.' + decimal;
        }
    }
}
</script>
<style scoped>
.title {
    background-image: -webkit-linear-gradient(#48BDFE, #7F47FB) !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
    font-weight: bolder;
}
</style>