<template>
    <div>
        <h5 class="text-dark" style="font-weight: 900;font-size:20px;">Pool Data</h5>
        <div class="p-2 rounded">
            <!-- <Line class="mb-3 text-dark" :options="chartOptions" :data="data"></Line> -->

            <div class="row">
                <div class="col-6 mb-2 ps-2 pe-1">
                    <div class="bg-pri p-2 rounded d-flex align-items-center">
                        <div class="bg-dark text-center p-2 me-1">
                            <img src="@/assets/surbodinate.png" style="width: 34px;" alt="">
                        </div>
                        <div>
                            <p class="mb-0 text" style="line-height: 2;"><b>732,136,{{ TotalProduct }}</b></p>
                            <div class="text2">TotalProduct</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-2 ps-1 pe-2">
                    <div class="bg-pri p-2 rounded d-flex align-items-center">
                        <div class="bg-dark text-center p-2 me-1">
                            <img src="@/assets/team.png" style="width: 34px;" alt="">
                        </div>
                        <div>
                            <p class="mb-0 text" style="line-height: 2;"><b>10,567, {{ ValidNode }}</b></p>
                            <div class="text2">ValidNode</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 ps-2 pe-1">
                    <div class="bg-pri p-2 rounded d-flex align-items-center">
                        <div class="bg-dark text-center p-2 me-1">
                            <img src="@/assets/surbodinate.png" style="width: 34px;" alt="">
                        </div>
                        <div style="word-break: break-all;">
                            <p class="mb-0 text" style="line-height: 1;word-break: break-all;"><b>6,338,992, {{ UserRevenue
                            }}</b></p>
                            <div class="text2">UserRevenue</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 ps-1 pe-2">
                    <div class="bg-pri p-2 rounded d-flex align-items-center">
                        <div class="bg-dark text-center p-2 me-1">
                            <img src="@/assets/team.png" style="width: 34px;" alt="">
                        </div>
                        <div>
                            <p class="mb-0 text" style="line-height: 2;"><b>27,687, {{ Participant }}</b></p>
                            <div class="text2">Participant</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend
// } from 'chart.js';
// import { Line } from 'vue-chartjs'
// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend
// )
export default {
    // components: {
    //     Line
    // },
    data() {
        return {
            TotalProduct: 214,
            ValidNode: 823,
            UserRevenue: 369,
            Participant: 198,
            data: {
                labels: ['06:00', '08:00', '10:00', '12:00', '14:00'],
                datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: '#fff',
                        data: [40, 39, 10, 40, 80]
                    }
                ]
            }
        }
    },
    mounted() {
        let date = new Date();
        let value = (date.getYear() + date.getMonth() + date.getDay()) + (((date.getHours() + date.getMinutes() + date.getSeconds()) * 10))
        console.log(value)
        this.TotalProduct += value + Math.floor(Math.random() * (10 - 1 + 1) + 1)
        this.UserRevenue += value + Math.floor(Math.random() * (10 - 1 + 1) + 1)
        this.Participant += value + Math.floor(Math.random() * (10 - 1 + 1) + 1)
        this.ValidNode += value + Math.floor(Math.random() * (10 - 1 + 1) + 1)


    }
}

</script>
<style scoped>
.text {
    color: #ff1c99 !important
}

.text2 {
    color: #7a90d3 !important;
}
</style>